class WebFlagComponent extends Component {

    static name() {
        return "WebFlagComponent";
    }

    static componentName() {
        return "WebFlagComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    getComputed() {
        return  {
            session: function () {
                return this.$store.getters.getSession;
            },
        };
    }

    getTemplate() {
        return`<span v-bind:class="{badge:true,'badge-success':data.WebUserFlag=='1','badge-danger':data.WebUserFlag!='1'}">
                    <template v-if="data.WebUserFlag=='1'">
                        {{tr('Enabled')}}
                    </template>
                    <template v-else>
                        {{tr('Disabled')}}
                    </template>
               </span>`;
    }
}

WebFlagComponent.registerComponent();

